import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { Clone } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class cc_flexbox_webcomponent extends HTMLElement {
    ci: web_cc_flexbox | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cc_flexbox(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cc-flexbox', cc_flexbox_webcomponent);

export class web_cc_flexbox extends CodeBrick {

    element: HTMLElement;
    cement_classes = [] as string[];

    has_click_handler = false;
    enable_click_event = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        
        element.innerHTML = `<div style="" class="cc-flexbox" id="${this.brick_id}"></div>`;

        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        if(input == "hidden") {
            //console.log("cc-flexbox "+this.brick_id+" cfg "+JSON.stringify(cfg));
            let slot = document.getElementById(this.brick_id);
            if(slot) {
                if(cfg) {
                    slot.classList.add("hidden");
                }
                else {
                    slot.classList.remove("hidden");
                }
            }
        }
        else if(input == "data") {
            return { "@" : cfg };
        }
        else if(input == "cfg") {
            let slot = document.getElementById(this.brick_id);
            if(slot) {
                let cfg_style = [] as string[];
                for(let option in cfg) {
                    if(option == "flex-wrap" && cfg[option]) {
                        //slot.style.flexWrap = cfg["flex-wrap"];
                        cfg_style.push(`flex-wrap:${cfg["flex-wrap"]}`);
                    }
                    else if(option == "align-items" && cfg[option]) {
                        //slot.style.alignItems = cfg["align-items"];
                        cfg_style.push(`align-items:${cfg["align-items"]}`);
                    }
                    else if(option == "align-content" && cfg[option]) {
                        //slot.style.alignContent = cfg["align-content"];
                        cfg_style.push(`align-content:${cfg["flex-wrap"]}`);
                    }
                    else if(option == "flex-direction" && cfg[option]) {
                        //slot.style.flexDirection = cfg["flex-direction"];
                        cfg_style.push(`flex-direction:${cfg["flex-direction"]}`);
                    }
                    else if(option == "justify-content" && cfg[option]) {
                        //slot.style.justifyContent = cfg["justify-content"];
                        cfg_style.push(`justify-content:${cfg["justify-content"]}`);
                    }
                    else if(option == "gap" && cfg[option]) {
                        //slot.style.gap = cfg["gap"];
                        cfg_style.push(`gap:${cfg["gap"]}`);
                    }
                    else if(option == "enable_click_event") {
                        this.enable_click_event = cfg[option];
                        if(this.enable_click_event) {
                            let div = document.getElementById(this.brick_id);
                            if(div && !this.has_click_handler) {
                                let self = this;
                                div.addEventListener("click",
                                    function() {
                                        if(self.enable_click_event) {
                                            self.cb_emit({ "@click": true });
                                        }
                                    }
                                );
                                this.has_click_handler = true;
                            }
                        }
                    }
                }
                let styles = Clone(cfg.styles || {});
                styles.flexbox = styles.flexbox || {};
                styles.flexbox["()"] = cfg_style.join(";");
                CBWebUtil.ApplyElementStyles(slot, { styles }, "flexbox");
            }
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {

        //console.log("cc-flexbox "+this.brick_id+" cb_initial_cement "+JSON.stringify(cements));

        let slot = document.getElementById(this.brick_id);
        if(slot) {    
            let innerHTML = '';
            if(this.blueprint.contains) {
                let  i = 0;
                for(let sub of this.blueprint.contains) {

                    let brick = CBWebUtil.BrickHtml(sub, this, i);

                    let prefix = sub.type.split('-')[0];
                    if(prefix.indexOf('i') == -1 && prefix[0] != 's') {

                        let lop_style = "";
                        //let lop_classes = "";
                        let cement = cements[i];
                        let hidden = "";
                        if(cement) {
                            for(let v in cement) {
                                // if(v == "style_classes") {
                                //     if(sub.cement[v]) {
                                //         lop_classes = cement[v].join(' ');
                                //     }
                                // }
                                // else if(v == "style") {
                                //     if(sub.cement[v]) {
                                //         for(let s in sub.cement[v]) {
                                //             lop_style += s+":"+sub.cement[v][s]+";";
                                //         }
                                //     }
                                // }
                                if(v == "styles" || v == "hidden") {

                                }
                                else if(cement[v]) {
                                    lop_style += v+':'+cement[v]+";"
                                }           
                            }
                            if(cement.hidden) {
                                hidden = "child-hidden"; //lop_classes += lop_classes ? " child-hidden" : "child-hidden";
                            }
                        }

                        //innerHTML += `<div style="${lop_style}" class="${lop_classes}" id="${this.brick_id}$${i}">${brick}</div>`;
                        innerHTML += `<div ${CBWebUtil.GetElementStylesString(hidden, cement, "", lop_style)} id="${this.brick_id}$${i}">${brick}</div>`;
                    }
                    else {
                        innerHTML += brick; //flexbox ignores child elements with position absolute, for thing like gap, which is wah we want for invisible components. We can't use display:none because that breaks stuff
                    }

                    i++;
                }
            }
            slot.innerHTML = innerHTML;
        }
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) { 
        let child = document.getElementById(this.brick_id+"$"+child_idx);
        if(child) {
            // if(cement.style_classes) {
            //     let new_cement_classes = [];
            //     for(let c of cement.style_classes) {
            //         if(c) {
            //             c = (c + "").trim();
            //             if(c) {
            //                 let cs = c.split(" ");
            //                 for(let s of cs) {
            //                     child.classList.add(s);
            //                     new_cement_classes.push(s);
            //                 }
            //             }
            //         }
            //     }
            //     //Remove the classes that are were in the cement but are now removed
            //     let classlist_array = Array.from(child.classList);
            //     for(let c of classlist_array) { 
            //         if(new_cement_classes.indexOf(c) == -1) {
            //             if(c != "child-hidden") {
            //                 child.classList.remove(c);
            //             }
            //         }
            //     }
            // }

            if(cement && cement.hidden) {
                child.classList.add("child-hidden");
            }
            else {
                child.classList.remove("child-hidden");
            }

            let style_cfg = { styles: cement };

            CBWebUtil.ApplyElementStyles(child, style_cfg, "");
        } 
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}


